.wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
  z-index: 200;
}
.overlay {
  z-index: 199;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
}
