@use '@queries' as *;

.mobile-modal {
  max-height: calc(100% - env(safe-area-inset-top)) !important;
  height: calc(100% - env(safe-area-inset-top)) !important;
  border-radius: 0 !important;

  > div {
    border-radius: 0 !important;
  }

  @include min-744-break {
    height: max-content !important;
    width: 428px;
    border-radius: 16px !important;
  }
}

.desktop-modal {
  width: 360px;
  outline: 4px solid var(--cst-authorization-outline);
  outline-offset: -4px;
  background-color: var(--cst-authorization-background);
  border-radius: var(--cornerradius14);

  @include min-1440-break {
    width: 436px;
    border-radius: var(--cornerradius18);
  }
}
